import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import {
    Section
} from 'react-scroll-section';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';


import Cards from './cards';

Home.propTypes = {
    data: PropTypes.array,
};

function Home(props) {

    return (
        <Section id="mint">
            <section className="tf-section tf-team">
                <div className="container container-flex">


                {/* <h4 className="title-2"  style={{ color: '#bd0507'}} >MINT OPENS AT 11:11 PST 18:11 UTC</h4>
                <p className="h8 sub-title" style={{ color: '#bd0507', fontSize: '20px !important' }}>11:11 PST / 18:11 UTC / 19:11 NIGERIA</p> */}

                    <div className="" data-aos="fade-up" data-aos-duration="800" style={{ marginTop: 20 }}>
                        <p className="h8 sub-title"></p>
                        <h4 className="title-2">MINT NOW!</h4>
                    </div>

                    {/* <video id="background-video" autoPlay loop muted playsInline>
                        <source src={VideoMP4} type="video/mp4" />
                
                    </video> */}

                    <div data-aos="fade-up" data-aos-duration="800">
                        <Cards />
                    </div>


                    <div data-aos="fade-up" data-aos-duration="800">
                        <h4 className="title-2" style={{ textAlign: 'center', marginTop: 100 }}>SETUP YOUR METAMASK</h4>

                        <div className="video-frame">

                            <iframe
                                // width="560"
                                // height="315"
                                src="https://www.youtube.com/embed/KaqXKttX-lA?controls=0"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="video-frame"
                            >

                            </iframe>
                        </div>

                    </div>

                </div>
            </section>
        </Section>

    );
}

export default Home;