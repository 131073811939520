
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, doc, getDocs } from "firebase/firestore/lite";


const firebaseConfig = {
  apiKey: "AIzaSyBE6AH1DJJjsWyGEPnUy_I8q3Uk4WCtRfo",
  authDomain: "korra-76612.firebaseapp.com",
  projectId: "korra-76612",
  storageBucket: "korra-76612.appspot.com",
  messagingSenderId: "842663864328",
  appId: "1:842663864328:web:cde18e6b407633807ec9bd",
  measurementId: "G-SHDEQHJ8MS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


 const db = getFirestore(app);

export const collectionWallet = collection(db, 'wallets');

export const docRef = doc(db, 'collection/doc');

export const fire = getFirestore(app);

export async function getWallets() {
  const citiesCol = collection(db, 'wallets');
  const citySnapshot = await getDocs(citiesCol);
  const cityList = citySnapshot.docs.map(doc => doc.data());
  return cityList;
}

