import React from 'react';
import '../scss/components/section.scss';
import '../scss/components/box.scss';
import Home from '../features/home';
import dataTeam from '../assets/fake-data/data-team';


function HomeOne(props) {

    return (

        <div className='home-1'>
            <Home data={dataTeam} />
        </div>


    );
}

export default HomeOne;

