import React, { useState, useEffect } from 'react';
import {
    useScrollSection
} from "react-scroll-section";
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';

import { Link, NavLink } from 'react-router-dom';
import menus from '../../pages/menu';

import './styles.scss';
import logo from '../../assets/images/logo/logo.png'

// import Button from '../button';

const Header = () => {
    const { active, activate } = useWeb3React();

    async function conn() {

        if (!active) {
            activate(new InjectedConnector({ supportedNetworks: [56] }));
        }
    }

    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);

    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };


    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };

    const homeSection = useScrollSection('home');
    const mintSection = useScrollSection('mint');
    const aboutSection = useScrollSection('about');
    const roadmapSection = useScrollSection('roadmap');

    function checkRoute(id) {
        if (id === 1) {
            return homeSection.onClick;
        }

        if (id === 2) {
            return mintSection.onClick;
        }

        if (id === 3) {
            return aboutSection.onClick;
        }

        if (id === 4) {
            return roadmapSection.onClick;
        }

        return homeSection.onClick;
    };

    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="header__logo">
                        <NavLink to="/"><img src={logo} alt="Crybox" /></NavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`} >
                        <ul id="menu-primary-menu" className="menu">
                            {
                                menus.map((data, idx) => (
                                    <li key={idx} onClick={checkRoute(data.id)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}
                                    >
                                        <Link to={data.links}>{data.name}</Link>
                                        {
                                            data.namesub &&
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map((submenu) => (
                                                        <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                    ))
                                                }
                                            </ul>
                                        }

                                    </li>
                                ))
                            }
                            <li key={2} className={`menu-item`}>
                                <a href="#" className={`menu-item`} rel="noopener noreferrer">
                                    Home
                                </a>
                            </li>
                            <li key={3} className={`menu-item`}>
                                <a href="https://memeheroes.io/about.html" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                About
                                </a>
                            </li>
                            <li key={4} className={`menu-item`}>
                                <a href="https://memeheroes.io/games.html" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                The Game
                                </a>
                            </li>
                       
                            <li key={6} className={`menu-item`}>
                                <a href="https://opensea.io/" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                    Opensea
                                </a>
                            </li>

                            <li key={6} className={`menu-item`}>
                                <a href="https://t.me/memeheroesgame" className={`menu-item`} target="_blank" rel="noopener noreferrer">
                                    Telegram
                                </a>
                            </li>
                        </ul>
                    </nav>

           
                    <button className="tf-button btn-effect" onClick={() => conn()} style={{ marginBottom: 50 }} >
                        <span className="boder-fade"></span>
                        <span className="effect"  style={{ fontSize: 10 }}>{active ? `CONNECTED 🟢` : `CONNECT`}</span>
                    </button>

                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
        </header>
    );
}

export default Header;