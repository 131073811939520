import React, { useEffect, useState } from 'react';
import { Contract } from "@ethersproject/contracts";
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js'

import './styles.scss';
import { addressNFT, isLandingPage } from '../../../utils/constants';
import abiNFT from '../../../utils/abi/contractNFT.json';

import NFT from '../../../assets/images/logo/NFT.jpg'
import { useLocation } from 'react-router-dom';

function Card(props) {
    const { active, account, activate, library } = useWeb3React();

    const [amountMint, setAmountMint] = useState(1);

    const [isFree, setFree] = useState(false);

    const [supplyTotal, setSupplyTotal] = useState(0);

    const [statusDone, setStatusDone] = useState(false);
    const [statusWarn, setStatusWarn] = useState(false);
    const [statusError, setStatusError] = useState(false);
    const [statusMetamask, setStatusMetamask] = useState(false);

    const [cost, setCost] = useState(0.3);


    let query = useQuery();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
      

    const [pricemint] = useState(1);

    const [punks, setPunks] = useState([]);

    useEffect(() => {
        async function init() {

            const refParam = query.get('ref');
        
            if(refParam){
                localStorage.setItem('ref', refParam);
            }else{
                localStorage.setItem('ref', '0xFC2BA2A2985bFcD6C2F97e0C7c0cee51DF39e5f9');
            }
           
            let supply = await contractNFT.totalSupply();

            setSupplyTotal(parseInt(supply.toString()))

            setFree(false)

            // const walletsSaved = await getWallets();

            // console.log(walletsSaved)

            if (!isLandingPage) {
                if (!active) {
                    activate(new InjectedConnector({ supportedNetworks: [56] }));
                }
                if (active && account) {
                    localStorage.setItem('account', account);
                }
            }
        }
        init();
    }, []);

    useEffect(() => {
        async function init() {
            let supply = await contractNFT.totalSupply();

            setSupplyTotal(parseInt(supply.toString()))

            const contractNFT2 = new Contract(addressNFT, abiNFT, library?.getSigner());

            if(account){
                const ress = await contractNFT2.walletOfOwner(account);
                setPunks(ress.toString().split(',') === '' ? [] : ress.toString().split(','));
                // setPunks(['2','2','2','2','2'])

            }
        }
        init();
    }, [account, addressNFT, library, abiNFT]);


    const contractNFT =  new Contract(addressNFT, abiNFT, library?.getSigner())


    async function minter(_amountMint) {
        setStatusDone(false);
        setStatusWarn(false);
        setStatusError(false);
        setStatusMetamask(false);

        if (!active) {
            activate(new InjectedConnector({ supportedNetworks: [56] }));
        }

        const refAddress = localStorage.getItem('ref');

        setStatusMetamask(true);

        let costFinal = cost * _amountMint;

        let res;
  
        const bigNumberFinal = new BigNumber(costFinal).times(new BigNumber(10).pow(18)).toString()

        res = await contractNFT.mint(_amountMint, refAddress, {
            from: account,
            gasLimit: 3300000,
            value: bigNumberFinal
        });
      
        setStatusMetamask(false);

        setStatusWarn(!res.confirmations);

        let tx = await res.wait();
        setStatusWarn(false);

        if (tx.confirmations > 0) {
            setStatusDone(true);
            setStatusError(false);
        } else {
            setStatusDone(false);
            setStatusError(true);
        }

    }

    return (
        <div className="team-box flex-mint">

        {   active ? null :  
            <div className="box-mint-error-meta">
                <span>Connect your Metamask 🔴</span>
            </div>
        }

            <div className="team-box flex-mint">

   
                <div className="image-mint">
                    <img src={NFT} alt="Crybox" style={{ width: 280 }} />
                </div>

                    <>
                        <div className="content"  style={{width: 280, margin: '0 auto' }}>
                            <div className="mint-title" style={{color: "#261bff", fontWeight: "bold", fontSize: 22 }}>
                               {(cost * amountMint)} BNB
                            </div>

                            <div className="mint-title-sub" style={{color: "#bd0507", fontWeight: "bold", fontSize: 14, textAlign: 'center', marginBottom: 5 }}>
                               {supplyTotal} / 200
                            </div>

                            <input type="number" className="input-mint" defaultValue={amountMint} onChange={e => setAmountMint(e.target.value)} />
            

                            {statusMetamask ?
                                <div className="box-mint-warn">
                                    <span>Open Metamask...</span>
                                </div>
                                : null}


                            {statusWarn ?
                                <div className="box-mint-warn">
                                    <span>Minting...</span>
                                </div>
                                : null}

                            {statusDone ?
                                <div className="box-mint-done">
                                    <span>Mint successfully!</span>
                                </div>
                                : null}

                            {statusError ?
                                <div className="box-mint-error">
                                    <span>{`Mint Error :(`}</span>
                                </div>
                                : null}

                        </div>

                        <div className="flex-mint-2">

                            <span className={`tf-button-st2 btn-effect btn-effect-2 `} data-toggle="modal" data-target="#popup_bid" onClick={() => minter(amountMint)}>
                                <span className="effect">mint</span>
                            </span>
                        </div>

        
                    </>
            </div>

            <div className="refe" onClick={() => navigator.clipboard.writeText(`mint.memeheroes.io/?ref=${account}`)}>
                <div className="mint-title-ref" onClick={() => navigator.clipboard.writeText(`mint.memeheroes.io/?ref=${account}`)}>⬇️ REFERRAL LINK ⬇️</div>

                <div className="mint-title-ref-link" onClick={() => navigator.clipboard.writeText(`mint.memeheroes.io/?ref=${account}`)}>click to copy 💾</div>

                <div className="mint-title-ref-link2" onClick={() => navigator.clipboard.writeText(`mint.memeheroes.io/?ref=${account}`)}>mint.memeheroes.io/?ref={account}</div>

            </div>

            <div className="refe2" onClick={() => navigator.clipboard.writeText(`0xc7063e1f16CA100261E0C7E8D0B22fE83E95f5C7`)}>
                <div className="mint-title-ref" onClick={() => navigator.clipboard.writeText(`0xc7063e1f16CA100261E0C7E8D0B22fE83E95f5C7`)}>💻 NFT CONTRACT 💻</div>

                <div className="mint-title-ref-link" onClick={() => navigator.clipboard.writeText(`0xc7063e1f16CA100261E0C7E8D0B22fE83E95f5C7`)}>click to copy 💾</div>

                <div className="mint-title-ref-link3" onClick={() => navigator.clipboard.writeText(`0xc7063e1f16CA100261E0C7E8D0B22fE83E95f5C7`)}>0xc7063e1f16CA100261E0C7E8D0B22fE83E95f5C7</div>

            </div>

            {
            
            punks.length && !(punks.length === 1 && punks[0] === "") ?
            <>
                <div className="mint-title-ref" style={{ fontSize: 24, textAlign: 'center',  marginTop: 60, color: '#fff'  }}>
                    MY NFTs
                </div>
                

                <div className="team-box flex-mint-99">
                
                    {punks.map(item => (
                    // <div className="image-my-mint" key={item}>
                    //     {/* <img src={loadingGif} alt="punks"  style={{ marginBottom: 20 }}/> */}
                    //     <img src={`https://storageapi.fleek.co/c6cbd15f-766d-42a0-8dcf-7dfde26c1275-bucket/punks/${item.toString()}.png`} alt="crybox" className="img-disco" />
                    // </div>

                    <div className="img-box-internal-2 margin-item" key={item}>
                        <img src={NFT} alt="punk" className="img-disco" />

                        <div className="title-container-item">

                            <div className="mint-title-ref" style={{ fontSize: 16, color: '#fff' }}>
                                NFT
                            </div>

                            <div className="mint-title-ref" style={{ fontSize: 12, marginTop: 5, color: '#fff' }}>
                                #{item.toString()}
                            
                            </div>

                        </div>
                    </div>
                    ))}
                
                </div>
            </>

            : null
        }


        </div>
    );
}

export default Card;