import React from 'react';
import '../scss/components/section.scss';
import '../scss/components/box.scss';
import EasterEgg from '../features/easter-egg';


function EasterEggHome() {

    return (

        <div className='home-1'>
            <EasterEgg />
        </div>


    );
}

export default EasterEggHome;

