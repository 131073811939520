import Home from "./Home";
import Page404 from "./404";
import EasterEggHome from "./EasterEgg";

const routes = [
  { path: '/', component: <Home />},
  { path: '/404', component: <Page404 />},
  { path: '/easter-egg', component: <EasterEggHome />},
]

export default routes;