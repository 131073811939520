import React, { useEffect, useState } from 'react';
import './styles.scss'
import {
    Section
} from 'react-scroll-section';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { getWallets } from '../../utils/firebase';

function EasterEgg() {

    const [wallets, setWallet] = useState(0);

    useEffect(() => {
        async function init() {
            const walletsSaved = await getWallets();
          
            setWallet(walletsSaved.length)
        }
        init();
    }, []);
    
    return (
        <Section id="mint">
            <section className="tf-section tf-team">
                <div className="container container-flex">

                    <h4 className="title-2">{wallets}</h4>

                </div>
            </section>
        </Section>

    );
}

export default EasterEgg;