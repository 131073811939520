const menus = [
    // {
    //     id: 1,
    //     name: 'Home',
    //     links: '/'
    // },

    // {
    //     id: 2,
    //     name: 'About',
    //     links: '#'
    // },

    // {
    //     id: 3,
    //     name: 'Conta',
    //     links: '#'
    // },
    // {
    //     id: 4,
    //     name: 'MY AVATARS',
    //     links: '/nfts'
    // },
    
    
]

export default menus;